import './App.css';
import { Link, ImmutableXClient, ImmutableMethodResults } from '@imtbl/imx-sdk';
import axios from 'axios'; // Import the axios library
import React, { useEffect, useState, useCallback, useMemo, Suspense, lazy, memo, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { setOpenAssetModalFunc, openAssetModal } from './AssetModalUtils'; // Import utility functions
import { setOpenOrderModalFunc, openOrderModal } from './OrderModalUtils'; 
import { setOpenSameCardsModalFunc, openSameCardsModal } from './ModalUtils';
import { setHandleRefreshFunc } from './RefreshUtils'; // Import the function
import {AssetResponse, TalentCard, Order, Asset} from './types';
import { setPageViewModalFunc } from './PageViewModalUtils';
import { openPageViewModal } from './PageViewModalUtils';
import WelcomeModal from './welcomeModal';
import TalentSignupModal from './talentSignupModal';
import { createRoot } from 'react-dom/client';

import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration



require('dotenv').config();

const OptimizedHeaderLazy = memo(lazy(() => import('./OptimizedHeader')));
const OrderModalLazy = memo(lazy(() => import('./OrderModal')));
const AssetModalLazy = memo(lazy(() => import('./AssetModal')));
const ExperienceDisplayLazy = memo(lazy(() => import('./experienceDisplay')));
const ModalSameCardsLazy = memo(lazy(() => import('./ModalSameCards')));
const PageViewModalLazy = memo(lazy(() => import('./pageViewModal')));

const CurrencyToggleLazy = lazy(() => import('./CurrencyToggle'));
const UserProfileLinkLazy = lazy(() => import('./UserProfileLink'));
const LanguageSelectorLazy = lazy(() => import('./LanguageSelector'));


type IframePositionKeys = 'left';

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};

interface SelectedAsset {
  asset: Asset;
  order_id: string;
  locationPath: string; // Adjust as needed if Location is not the correct type
}



async function fetchTalentCardProp(tokenAddress: string, tokenId: string) {
  try {

    const currentHostname = window.location.href;
    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const linkUrl = currentHostname.includes(`${stagingKey}`) 
      ? `https://${stagingKey}.peaxel.me` 
      : 'https://peaxel.me';

    console.log('linkUrl',linkUrl);
    console.log('stagingKey',stagingKey);
    console.log('currentHostname',currentHostname);

    const url = new URL(`${linkUrl}/wp-json/custom/v1/talent-card-prop/`);
    url.search = new URLSearchParams({ token_address: tokenAddress, token_id: tokenId }).toString();

    const response = await fetch(url.toString());
    if (!response.ok) {
      console.error('Error fetching talent card data:', response.statusText);
      return null;
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching talent card data:', error);
    return null;
  }
}

async function balancePush(walletBalancePush: any) {
  const walletAddressPush = localStorage.getItem('walletAddress') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletNetworkPush = localStorage.getItem('walletNetwork') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletStarkPush = localStorage.getItem('walletStark') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletEmailPush = localStorage.getItem('walletEmail') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletProviderPush = localStorage.getItem('walletPreference') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletCurrencyPush = localStorage.getItem('pxlCurrency') || ''; // Retrieve the 'walletAddress' value from local storage
  const walletLanguagePush = localStorage.getItem('pxlLanguage') || ''; // Retrieve the 'walletAddress' value from local storage

  window.postMessage(
    {
      type: 'UPDATE_WALLET_ADDRESS',
      walletAddress: walletAddressPush,
      walletNetwork: walletNetworkPush,
      walletStark: walletStarkPush,
      walletEmail: walletEmailPush || '',
      walletPreference: walletProviderPush,
      pxlCurrency: walletCurrencyPush,
      pxlBalance: walletBalancePush,
      pxlLanguage: walletLanguagePush,
    },
    window.location.origin // Only send the message to the same origin
  );
}





async function displayAllAuctions(
  assets: Asset[],
  client: ImmutableXClient | null,
  refresh: number, 
  talentName: string,
  state: string,
  todisplay: string,
  locationPage: string,
  modal: string,
  t: (key: string, options?: any) => string // Add t as a parameter
): Promise<void> {
  const assetsContainer = document.getElementById("tokenContainerAllAuctions");
  const isMobile = window.innerWidth <= 767;


  if (assetsContainer) {
    // Set the key attribute for re-rendering
    assetsContainer.setAttribute('key', refresh.toString());
  }

  const currentHostname = window.location.href;
    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const linkUrl = currentHostname.includes(`${stagingKey}`) 
      ? `https://${stagingKey}.peaxel.me` 
      : 'https://peaxel.me';

  const defaultAsset = {
    collection: {
      icon_url: "",
      name: "Default Collection",
    },
    created_at: "",
    description: "Default Description",
    id: "",
    image_url: `${linkUrl}/wp-content/uploads/2024/06/ADD-CARD-LINEUP-PEAXEL-3.png`,
    metadata: {
      country: "Default Country",
      description: "Default Metadata Description",
      field: "Default Field",
      first_name: "Default First Name",
      image_url: `${linkUrl}/wp-content/uploads/2024/06/ADD-CARD-LINEUP-PEAXEL-3.png`,
      name: "Default Name",
      reward_per_token: "",
      token_number: "",
      total_tokens: "",
      total_tokens_first_year: "",
      total_tokens_forth_year: "",
      total_tokens_second_year: "",
      total_tokens_third_year: "",
      vote_per_token: "",
    },
    name: "emptyOne",
    status: "Default Status",
    token_address: "",
    token_id: "",
    updated_at: "",
    uri: null,
    user: "Default User",
    talentCard: {
      token_address: '',
      token_id: '',
      card_id: '',
      name: '',
      owner: '',
      date_update: '',
      image_url: '',
      token_new_url: `${linkUrl}/wp-content/uploads/2024/06/ADD-CARD-LINEUP-PEAXEL-3.png`,
      card_experience: 10,
      card_force: '50',
      ETH_value: '',
      EUR_value: '',
      USD_value: '',
      lineup: 1,
      ETH_ratio: '',
      EUR_ratio: '',
      USD_ratio: '',
      buyer_max: '',
      current_order: '',
      desired_dollar: '',
      desired_eth: '',
      desired_euro: '',
      dollar_max: '',
      eth_max: '',
      euro_max: '',
      offers_count: '',
      pending: '',
      toTransfer: '',
      total_lineup: '',
      profile_new_url: `${linkUrl}/wp-content/uploads/2024/06/ADD-CARD-LINEUP-PEAXEL-3.png`,
      similar_cards_total: 0,
      similar_cards_on_sale: 0,
      similar_cards_total_pending: 0,
      lineup_per_talent: 0,
      available_cards: 0, //number of available cards to buy for the same talent
      potential_buyers: '',
      total_pending: 0,
      country: '',
state: '',
city: '',
main_nationality: '',
main_activity: '',
main_category: '',
league: '',
league_url: '',
club: '',
club_url: '',
national_team: '',
national_team_url: '',
tag1: '',
tag2: '',
tag3: '',
tag4: '',
tag5: '',
prize1: '',
prize2: '',
prize3: '',
prize4: '',
prize5: '',
talent_age: '',
    talent_name_original: '',
    talent_first_name: '',
    talent_last_name: '',
    talent_instagram: '',
    talent_youTube: '',
    talent_facebook: '',
    talent_tiktok: '',
    talent_X: '',
    talent_pinterest: '',
    talent_linkedIn: '',
    talent_twitch: '',
    talent_website: '',
    talent_force: '',
    talent_description: '',
    instagram_followers: '',
    talent_birthdate: '',
    talent_experience: '',
    },
  };



  if (locationPage.includes("market")
  || locationPage.includes("team")
  || locationPage.includes("dashboard")
  || locationPage.includes("competition")
  || modal === 'profile') {
    if (!client) {
      console.error("ImmutableXClient is not initialized.");
      return;
    }

    const assetsWithImages = await Promise.all(
      assets.map(async (asset) => {
        if (!asset.talentCard) {
          const talentCardProp = await fetchTalentCardProp(asset.token_address, asset.token_id);
          if (talentCardProp) {
            asset.talentCard = talentCardProp;
          } else {
            console.warn(`Talent card not found for token: ${asset.token_address}, ${asset.token_id}`);
            asset.talentCard = defaultAsset.talentCard; // Set a default empty object or null
          }
        }
        return asset;
      })
    );

    console.log('assets_fi',assets);

    const talentVsCards = ((locationPage.includes("market") && state === 'allTalents') || (locationPage.includes("team") && state === 'allTalents') );
    const teamshortStatus = (todisplay === 'teamshort');
    const cardsportfolioStatus = (todisplay === 'cardsportfolio');
    const lineupStatus = (todisplay === 'lineup');
    const sameCardsStatus = (todisplay === 'samecards');
    const buyoffersStatus = (todisplay === 'buyoffers');
    const selloffersStatus = (todisplay === 'selloffers');
    const addlineupStatus = (todisplay === 'addlineup');

    const dispCurrency = localStorage.getItem("pxlCurrency");
    const currentUser = localStorage.getItem ("walletAddress");
    const currentTalent = localStorage.getItem ("Nprofile");
    const assetsPerRow = isMobile ? 1 : 1;

    if ((currentUser && currentUser.length < 6) || (currentUser === null)) {
    const currentUser='0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a';
    localStorage.setItem('walletAddress', currentUser);
    }

    let filteredAssets: Asset[];

    if (locationPage.includes("team") 
  || sameCardsStatus 
  || state === 'offersReceived'
  || state === 'listedTalents') {
      // Filter assets where asset.name equals currentUser
      assets = assets.filter(asset => asset.user === currentUser);
    }

  
 
    // Existing logic for talentVsCards and general filtering - only 1 asset per different name
    if (talentVsCards) {
      const displayLimit = Infinity;
    
      const uniqueAssets = assets.reduce((acc: Asset[], asset: Asset) => {
        if (!acc.some(a => a.name === asset.name)) {
          acc.push(asset);
        }
        
        return acc;
      }, []);
    
      filteredAssets = uniqueAssets.slice(0, displayLimit);
    } else {
      filteredAssets = assets;
    }



    /// SAMPLING OF LISTED CARDS - AND NUMBER OF PENDING OFFERS AT THE CURRENT USER LEVEL

    let assetsSampling = null;
    let assetsSampling_ListedForSale = null;
    let userNumberCards = 0;
    let numberOfUniqueAssetNames = 0;
    let numberListedForSales = 0;
    let total_pending = 0;
   
     
      assetsSampling = filteredAssets.filter(
      (asset) => ((asset.talentCard?.owner === currentUser))
      );
      if(assetsSampling[0]){
      total_pending = assetsSampling[0].talentCard?.total_pending || 0;
      localStorage.setItem('pendingOffers',total_pending.toString());
      }
      

      if ((state === 'overView') || locationPage.includes("dashboard")) {
      assetsSampling_ListedForSale = assetsSampling.filter(
        (asset) => (parseInt(asset.talentCard?.current_order) > 10)
      );
      numberListedForSales=assetsSampling_ListedForSale.length;
      const assetNames = assetsSampling.map(asset => asset.name);
      const uniqueAssetNames = new Set(assetNames);
      numberOfUniqueAssetNames = uniqueAssetNames.size;
      userNumberCards = assetsSampling.length;
    }

    //////////////////////////////

    
    let assetsOnSale; 
    //let assetsUser = null; BAM
    let numberCardsUser = 0;
    //let assetsListed=null; BAM
    let numberListedAssets = 0;

    if (modal !== 'profile') {

    if (addlineupStatus) {
      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.owner === currentUser 
          && (parseInt(asset.talentCard?.current_order) < 10) 
          )
      ); 
      
    } else if (locationPage.includes("market"))  {
    
    if (state === 'allAuctions') {
    assetsOnSale = filteredAssets.filter(
      (asset) => (parseInt(asset.talentCard?.current_order) > 10)
    )
    }
    else if (state === 'allTalents') {
      console.log('gege3');
      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.owner.length > 8)
      )
    }
    else if (state === 'managersSales') {
      assetsOnSale = filteredAssets.filter(
        (asset) => (parseInt(asset.talentCard?.current_order) > 10 && asset.talentCard?.owner !== '0xbdc8186faa4c50061948edfdfc4a5b534c42a45d')
        )
    }
    else if (state === 'offersReceived') {
      assetsOnSale = filteredAssets.filter(
        (asset) => (parseInt(asset.talentCard?.pending) > 0 && asset.talentCard?.owner === currentUser)
        )
    } else if ((state === 'offersSent') && currentUser) {
      assetsOnSale = filteredAssets.filter((asset) => {
        // Ensure the potential_buyers field exists
        if (asset.talentCard?.potential_buyers) {
          // Convert the potential_buyers string to an array
          const potentialBuyersArray = asset.talentCard?.potential_buyers.split(',');
          // Check if currentUser is in the potentialBuyersArray and current_order is greater than 10
          return potentialBuyersArray.includes(currentUser) && parseInt(asset.talentCard?.current_order) > 10;
        }
        return false; // If potential_buyers field does not exist, exclude the asset
      });
    } else if (state === 'listedTalents') {
      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.owner===currentUser && parseInt(asset.talentCard?.current_order) >10)
        )
    }
    
    } else if (teamshortStatus) {
      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.owner === currentUser)
      )

      const uniqueAssetsMap = new Map();
      assetsOnSale?.forEach((asset) => {
        if (!uniqueAssetsMap.has(asset.talentCard?.name)) {
          uniqueAssetsMap.set(asset.talentCard?.name, asset);
        }
      });
      assetsOnSale = Array.from(uniqueAssetsMap.values());
      if (state === 'overView') {
        if (isMobile) {assetsOnSale = assetsOnSale.slice(0, 4);}
        else {assetsOnSale = assetsOnSale.slice(0, 5);}
      } 
      
    } else if (cardsportfolioStatus) {
      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.owner === currentUser)
      )
      if (state === 'overView') {
          if (isMobile) {assetsOnSale = assetsOnSale.slice(0, 4);}
          else {assetsOnSale = assetsOnSale.slice(0, 5);}
        } 

    } else if (lineupStatus) {
          assetsOnSale = filteredAssets.filter(
            (asset) => (asset.talentCard?.owner === currentUser && asset.talentCard?.lineup == 1)
          );
          const defaultCount = 5 - assetsOnSale.length;
          for (let i = 0; i < defaultCount; i++) {
          assetsOnSale.push(defaultAsset);
          }
          
    } else if (sameCardsStatus) {
          assetsOnSale = filteredAssets.filter(
            (asset) => (asset.talentCard?.owner === currentUser && asset.name === talentName)
          );  

    } 
     } else {
     
      const consideredTalent = talentName.toUpperCase();
      console.log('theTalent',consideredTalent);

      let assetsUser;
      let assetsListed; 
      let assetsOwners;
      let numberOwners =0;

      assetsOnSale = filteredAssets.filter(
        (asset) => (asset.talentCard?.name === consideredTalent)
      );
      

      assetsUser = assetsOnSale.filter(
        (asset) => ((asset.talentCard?.owner === currentUser))
      );

      numberCardsUser = assetsUser.length;

      assetsListed = assetsOnSale.filter(
        (asset) => ((parseInt(asset.talentCard?.current_order) > 10) && asset.talentCard?.owner !== currentUser)
      );

      numberListedAssets = assetsListed.length;
      
      const uniqueOwners = new Set(assetsOnSale.map(asset => asset.talentCard?.owner));

      numberOwners = uniqueOwners.size;
      

      if (assetsOnSale) {
    
        let tableHtml2= '';
        if (numberCardsUser>1){
        tableHtml2 = `s (${numberCardsUser})`;
        } else if (numberCardsUser == 1){
        tableHtml2 = `&nbsp;(1)`;
        }
        else {
        tableHtml2 = `&nbsp;(0)`;
        }
        
        let tableHtml3= '';
        if (numberListedAssets>1){
        tableHtml3 = `s (${numberListedAssets})`;
        } else if (numberListedAssets == 1){
        tableHtml3 = `&nbsp;(1)`;
        }
        else {
        tableHtml3 = `&nbsp;(0)`;
        }

        let tableHtml4= '';
        if (numberOwners>1){
        tableHtml4 = `${numberOwners} ${t('managers')}`;
        } else if (numberOwners == 1){
        tableHtml4 = `1 ${t('manager')}`;
        }
        else {
        tableHtml4 = `0 ${t('managers')}`;
        }

        const targetDiv2 = document.getElementById('seeMyCards');
        if (targetDiv2) {
                targetDiv2.innerHTML = ''; // Clear the inner HTML
                targetDiv2.className = ''; // Clear className if needed
                targetDiv2.innerHTML = tableHtml2; // Set the HTML string
        }
        
        const targetDiv3 = document.getElementById('buyMyCards');
              if (targetDiv3) {
                targetDiv3.innerHTML = ''; // Clear the inner HTML
                targetDiv3.className = ''; // Clear className if needed
                targetDiv3.innerHTML = tableHtml3; // Set the HTML string
              }
        
              const targetDiv2Bottom = document.getElementById('seeMyCardsBottom');
              if (targetDiv2Bottom) {
                      targetDiv2Bottom.innerHTML = ''; // Clear the inner HTML
                      targetDiv2Bottom.className = ''; // Clear className if needed
                      targetDiv2Bottom.innerHTML = tableHtml2; // Set the HTML string
              }
              
              const targetDiv3Bottom = document.getElementById('buyMyCardsBottom');
                    if (targetDiv3Bottom) {
                      targetDiv3Bottom.innerHTML = ''; // Clear the inner HTML
                      targetDiv3Bottom.className = ''; // Clear className if needed
                      targetDiv3Bottom.innerHTML = tableHtml3; // Set the HTML string
                    }

              const targetDiv4 = document.getElementById('managersCount');
              if (targetDiv4) {
                targetDiv4.innerHTML = ''; // Clear the inner HTML
                targetDiv4.className = ''; // Clear className if needed
                targetDiv4.innerHTML = tableHtml4; // Set the HTML string
              }
       
      } 
      
    }

    if (buyoffersStatus && modal === 'profile') {
      assetsOnSale = filteredAssets.filter(
        (asset) => ((asset.name === talentName.toUpperCase()) && (parseInt(asset.talentCard?.current_order) > 10) && asset.talentCard?.owner!== currentUser)
      );
      
    } 
    if (selloffersStatus && modal === 'profile') {
      assetsOnSale = filteredAssets.filter(
      (asset) => (asset.talentCard?.owner === currentUser && asset.name === talentName.toUpperCase())
      ); 

    }


    //////// PRESENTATION OF TITLES & STATISTICS

    /// SAMPLING CARDS - GENERAL MARKET STATS
   
    
    let numberOfUniqueAssetNamesMarket = 0;
    let numberManagerMarket = 0;
    let numberCardsListedForSaleMarket = 0;
    let assetsMarket;

    if (locationPage === "dashboard") {
 
    const uniqueAssetNamesMarket = new Set(filteredAssets.map(asset => asset.name));
    numberOfUniqueAssetNamesMarket = uniqueAssetNamesMarket.size;

    const uniqueManagerMarket = new Set(filteredAssets.map(asset => asset.talentCard?.owner));
    numberManagerMarket = uniqueManagerMarket.size;
      
    assetsMarket = filteredAssets.filter(
        (asset) => (parseInt(asset.talentCard?.current_order) > 10)
      );

    numberCardsListedForSaleMarket = assetsMarket.length;



 const tableHtml = `
 <div style="color: #FAFAFA; width: 100% !important; font-weight: 400; font-size: 120%; padding-top: 20px; padding-bottom: 20px; border-top: 1px solid #161616;"> <i style="opacity: 0.5; font-size: 75%;" class="fa-solid fa-users-rectangle"></i> ${t ('myTalents')}</div>`;
  
 
const tableHtml2 = `
<div style="color: #FAFAFA; width: 100% !important; font-weight: 400; font-size: 120%; padding-top: 20px; padding-bottom: 20px; border-top: 1px solid #161616;"> <i style="opacity: 0.5; font-size: 75%;" class="fa-solid fa-shield-halved"></i> ${t ('market')}</div>`;
  
    const tableHtml5 = `
    <div class="teamStatistics">
      <div class="marketTopStat" >
        <span class="numbTeamTopStat">${numberOfUniqueAssetNamesMarket}</span>
        <span class="labelTeamTopStat">${t('talents')}</span>
      </div>
      <div class="marketTopStat">
        <span class="numbTeamTopStat">${numberManagerMarket}</span>
        <span class="labelTeamTopStat">${t('managers')}</span>
      </div>
      <div class="marketTopStat">
        <span class="numbTeamTopStat">${numberCardsListedForSaleMarket}</span>
        <span class="labelTeamTopStat">${t('listedCards')}</span>
      </div>
    </div>
  `;

  const targetDiv5 = document.getElementById('marketStats');
  if (targetDiv5) {
    targetDiv5.innerHTML = ''; // Clear the inner HTML
    targetDiv5.className = ''; // Clear className if needed
    targetDiv5.innerHTML = tableHtml5; // Set the HTML string
  }

  //const targetDiv = document.getElementById('titleStats');
  //if (targetDiv) {
   
  //  targetDiv.className = ''; // Clear className if needed
  //  targetDiv.innerHTML = tableHtml; // Set the HTML string
  //}
  const targetDiv2 = document.getElementById('titleMarket');
  if (targetDiv2) {
    targetDiv2.innerHTML = ''; // Clear the inner HTML
    targetDiv2.className = ''; // Clear className if needed
    targetDiv2.innerHTML = tableHtml2; // Set the HTML string
  }


   }
    console.log('locationPage',locationPage);
    
  
  
  
    if (state === 'overView' || locationPage === "dashboard") {
      const tableHtml = `
        <div style="color: #FAFAFA; width: 100% !important; font-weight: 400; font-size: 120%;">
          <i style="opacity: 0.5; font-size: 75%;" class="fa-solid fa-bolt-lightning"></i> ${t ('myLineup')}
        </div>`;
      const targetDiv = document.getElementById('titleLineup');
      if (targetDiv) {
        targetDiv.innerHTML = ''; // Clear the inner HTML
        targetDiv.className = ''; // Clear className if needed
        targetDiv.innerHTML = tableHtml; // Set the HTML string
      }

      if (assetsSampling && assetsSampling[1]) { // Ensure assetsSampling and the second element exist
        const lineupStatusStat = (parseInt(assetsSampling[1].talentCard?.total_lineup) * 20);
    
        let dependance = ''; 
        let dependance2 = '';
        let dependance3 = '';
        
         // Ensure numberListedForSales is defined
          if (numberListedForSales == 0) {
            dependance = 'noShowData';
            dependance2 = 'noShowData';
            dependance3 = 'noShowData';
          } else {
            dependance = 'teamTopStat2';
            dependance2 = 'numbTeamTopStat';
            dependance3 = 'labelTeamTopStat';
          }
        
        const tableHtml8 = `
            <div class="teamTopStat">
              <span class="numbTeamTopStat">${lineupStatusStat}%</span>
              <span class="labelTeamTopStat">${t ('lineupCompletion')}</span>
            </div>`;
        const tableHtml9 = `
            <div class="teamTopStat">
              <span class="numbTeamTopStat">${numberOfUniqueAssetNames}</span>
              <span class="labelTeamTopStat">${t ('talents')}</span>
            </div>`;
        const tableHtml10 = `
            <div class="teamTopStat">
              <span class="numbTeamTopStat">${userNumberCards}</span>
              <span class="labelTeamTopStat">${t ('collectedCards')}</span>
            </div>`;
        const tableHtml11 = `
            <div class="${dependance}">
              <span class="${dependance2}">${numberListedForSales}</span>
              <span class="${dependance3}">${t ('listedCards')}</span>
            </div>`;
        
    
        const targetDiv8 = document.getElementById('teamStats1');
        if (targetDiv8 && (state === "overView" || locationPage === "dashboard")) {
          targetDiv8.innerHTML = ''; // Clear the inner HTML
          targetDiv8.className = ''; // Clear className if needed
          targetDiv8.innerHTML = tableHtml8; // Set the HTML string
        } 
        //else if (targetDiv8 && (state !== "overView" || !locationPage.includes("dashboard"))) {
        //  targetDiv8.innerHTML = ''; // Clear the inner HTML
        //}

        const targetDiv9 = document.getElementById('teamStats2');
        if (targetDiv9 && (state === "overView" || locationPage === "dashboard")) {
          targetDiv9.innerHTML = ''; // Clear the inner HTML
          targetDiv9.className = ''; // Clear className if needed
          targetDiv9.innerHTML = tableHtml9; // Set the HTML string
        } 
       // else if (targetDiv9 && (state !== "overView" || !locationPage.includes("dashboard"))) {
       //   targetDiv9.innerHTML = ''; // Clear the inner HTML
       // }

        const targetDiv10 = document.getElementById('teamStats3');
        if (targetDiv10 && (state === "overView" || locationPage === "dashboard")) {
          targetDiv10.innerHTML = ''; // Clear the inner HTML
          targetDiv10.className = ''; // Clear className if needed
          targetDiv10.innerHTML = tableHtml10; // Set the HTML string
        } 
        //else if (targetDiv10 && (state !== "overView" || !locationPage.includes("dashboard"))) {
        //  targetDiv10.innerHTML = ''; // Clear the inner HTML
        //}

        const targetDiv11 = document.getElementById('teamStats4');
        if (targetDiv11 && (state === "overView" || locationPage === "dashboard")) {
          targetDiv11.innerHTML = ''; // Clear the inner HTML
          targetDiv11.className = ''; // Clear className if needed
          targetDiv11.innerHTML = tableHtml11; // Set the HTML string
        } 
        //else if (targetDiv11 && (state !== "overView" || !locationPage.includes("dashboard"))) {
        //  targetDiv11.innerHTML = ''; // Clear the inner HTML
        //}
      }
    } 


    if ((state !== 'overView' && locationPage === 'team')) { 
      
      const tableHtml1 = `
        `;
      const targetDiv1 = document.getElementById('titleLineup');
      if (targetDiv1) {
        targetDiv1.innerHTML = ''; // Clear the inner HTML BAM
        targetDiv1.className = ''; // Clear className if needed
        targetDiv1.innerHTML = tableHtml1; // Set the HTML string
      }

      const tableHtml2 = `
        `;
      const targetDiv2 = document.getElementById('titleTeamShort');
      if (targetDiv2) {
        targetDiv2.innerHTML = ''; // Clear the inner HTML BAM
        targetDiv2.className = ''; // Clear className if needed
        targetDiv2.innerHTML = tableHtml2; // Set the HTML string
      }

      const tableHtml3 = `
        `;
      const targetDiv3 = document.getElementById('titleCardsPortfolio');
      if (targetDiv3) {
        targetDiv3.innerHTML = ''; // Clear the inner HTML BAM
        targetDiv3.className = ''; // Clear className if needed
        targetDiv3.innerHTML = tableHtml3; // Set the HTML string
      }

      const targetDivu = document.getElementById('teamStats');
      if (targetDivu) {targetDivu.innerHTML = '';} // Clear the inner HTML
    } 
    
    if (teamshortStatus && state === 'overView') {
      const tableHtml = `
        <div style="color: #FAFAFA; width: 100% !important; font-weight: 400; font-size: 120%; padding-top: 20px; border-top: 1px solid #161616;">
          <i style="opacity: 0.5; font-size: 75%;" class="fa-solid fa-users-rectangle"></i> ${t ('myTalents')}
        </div>`;
      const targetDiv = document.getElementById('titleTeamShort');
      if (targetDiv) {
        targetDiv.innerHTML = ''; // Clear the inner HTML BAM
        targetDiv.className = ''; // Clear className if needed
        targetDiv.innerHTML = tableHtml; // Set the HTML string
      }
    } 

    if (cardsportfolioStatus && state === 'overView') {
      const tableHtml = `
        <div style="color: #FAFAFA; width: 100% !important; font-weight: 400; font-size: 120%; padding-top: 20px; border-top: 1px solid #161616;">
          <i style="opacity: 0.5; font-size: 75%;" class="fa-solid fa-id-badge"></i> ${t ('myCards')}
        </div>`;
      const targetDiv = document.getElementById('titleCardsPortfolio');
      if (targetDiv) {
       // targetDiv.innerHTML = ''; // Clear the inner HTML BAM
        targetDiv.className = ''; // Clear className if needed
        targetDiv.innerHTML = tableHtml; // Set the HTML string
      }
    } 

 
   //////////////////////////////////////////// END LOCAL TITLES + STATS

    let assetsRowContainer = document.createElement("div");
    assetsRowContainer.classList.add("assets-row-team");

    assetsOnSale?.forEach((asset, index) => {
      if (index % assetsPerRow === 0) {
      }

      let tokenDiv;
      let token1;
      let token2;
     
      if (sameCardsStatus) {
        tokenDiv = document.getElementById(`tokensteamshortname`);
        } else if (buyoffersStatus) {
          tokenDiv = document.getElementById(`tokensteamshortname`);
        } else if (selloffersStatus) {
             tokenDiv = document.getElementById(`tokensteamshortname`);
            } else if (addlineupStatus) {
              tokenDiv = document.getElementById(`tokensteamshortname`);
        } else {
      if (locationPage.includes("market")) {
        tokenDiv = document.getElementById(`tokensAllAuctions`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
      } 
      else if (lineupStatus) {
        tokenDiv = document.getElementById(`tokensLineup`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensteamshort`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokenscardsportfolio`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else if (teamshortStatus) {
        tokenDiv = document.getElementById(`tokensteamshort`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensLineup`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokenscardsportfolio`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else if (cardsportfolioStatus) {
        tokenDiv = document.getElementById(`tokenscardsportfolio`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensLineup`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokensteamshort`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else {
        tokenDiv = document.getElementById(`ghost`);
      }
    }

      if (tokenDiv) {
        tokenDiv.innerHTML = "";
      }
    });
    

      assetsOnSale?.forEach((asset, index) => {
      if (index % assetsPerRow === 0) {
      assetsRowContainer = document.createElement("div");
      assetsRowContainer.classList.add("assets-row-team");
      }

      const tokenId = asset.name;
      let tokenDiv;
      let token1;
      let token2;
      let token3;

      if (sameCardsStatus) {
        tokenDiv = document.getElementById(`tokensteamshortname`);
        } else if (buyoffersStatus) {
            tokenDiv = document.getElementById(`tokensteamshortname`);
        } else if (selloffersStatus) {
                tokenDiv = document.getElementById(`tokensteamshortname`);
              } else if (addlineupStatus) {
                tokenDiv = document.getElementById(`tokensteamshortname`);
        } else {
      if (locationPage.includes("market")) {
        tokenDiv = document.getElementById(`tokensAllAuctions`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
      } 
      else if (lineupStatus) {
        tokenDiv = document.getElementById(`tokensLineup`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensteamshort`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokenscardsportfolio`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else if (teamshortStatus) {
        tokenDiv = document.getElementById(`tokensteamshort`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensLineup`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokenscardsportfolio`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else if (cardsportfolioStatus) {
        tokenDiv = document.getElementById(`tokenscardsportfolio`);
        if (tokenDiv) {
          tokenDiv.style.display = 'flex';
        }
        token1 = document.getElementById(`tokensLineup`);
        if (token1 && state !=='overView') {token1.innerHTML = ""; token1.style.display = 'none';}
        token2 = document.getElementById(`tokensteamshort`);
        if (token2 && state !=='overView') {token2.innerHTML = ""; token2.style.display = 'none';}
      } else {
        tokenDiv = document.getElementById(`ghost`);
      }
    }



      if (tokenDiv) {

  

        const assetDiv = document.createElement("div");
        assetDiv.classList.add("asset");

        const assetStart = document.createElement("div");
        assetStart.classList.add("asset-start");

        const assetDetails = document.createElement("div");
        assetDetails.classList.add("asset-details");

      
        
        

      if (!talentVsCards || sameCardsStatus || buyoffersStatus || selloffersStatus) { 
       
      if (!teamshortStatus) {

      if (parseInt(asset.talentCard?.pending) > 0 
      && ((locationPage.includes("market") && state === 'listedTalents')
      || (locationPage.includes("market") && state === 'offersReceived')
      || (locationPage.includes("market") && state === 'allAuctions')
      || (locationPage.includes("market") && state === 'managersSales')
      || ((locationPage.includes("team") || locationPage.includes("dashboard")|| modal === 'profile') 
      //&& todisplay === 'lineup'
      )
      
      )) { 
        
        if (state !== 'allAuctions') {
          if (state !== 'managersSales'){ 
        const label = document.createElement("span");
        label.classList.add("asset-group-label");
        label.innerHTML = `<span class="asset-alert-pink" style="color:#ffffff; font-weight:700;">${parseInt(asset.talentCard?.pending)}&nbsp;<i style="color:#ffffff;" class="fa-solid fa-money-check-dollar"></i></span>`;
        assetDiv.appendChild(label); }
      }}

        const image = document.createElement("img");
        image.classList.add("asset-image");
        image.loading = "lazy";
        image.src = asset.talentCard?.token_new_url || `${linkUrl}/wp-content/uploads/2024/06/ADD-CARD-LINEUP-PEAXEL-3.png`;
        if (asset.name !== 'emptyOne') {  
          image.onclick = () => {
            openPageViewModal(asset,'');
          };
        } else {
          image.onclick = () => {
            openSameCardsModal('','addlineup');
          };
        }
        assetStart.appendChild(image);


      }

      }

      if (talentVsCards || teamshortStatus) { 


        if (!talentVsCards) {
        const label = document.createElement("span");
        label.classList.add("asset-group-label");
        
        let spart1 = "";
        let spart2 = "";
        
        if (asset.talentCard?.similar_cards_total_pending !== '0' && asset.talentCard?.owner === currentUser && !locationPage.includes("?activesection=allTalents") ) { 
            spart1 = `<span class="asset-alert-pink" style="color:#ffffff; font-weight:700;">${parseInt(asset.talentCard?.similar_cards_total_pending)}&nbsp;<i style="color:#ffffff;" class="fa-solid fa-money-check-dollar"></i></span>`;
        }
        
        if (asset.talentCard?.lineup_per_talent > 0 && asset.talentCard?.owner === currentUser) { 
            spart2 = `<span class="asset-alert-second" style="color:#ffffff; font-weight:700;">${asset.talentCard?.lineup_per_talent}&nbsp;<i style="color:#ffffff;" class="fa-solid fa-bolt-lightning"></i></span>`;
        }
        
        label.innerHTML = `${spart1}${spart2}`;
        assetDiv.appendChild(label);
      }
         

        
          const image = document.createElement("img");
          image.classList.add("asset-image");
          image.loading = "lazy";
          image.src = asset.talentCard?.profile_new_url;
          image.onclick = () => {
            openPageViewModal(asset,'');
          };
          assetStart.appendChild(image);

        const force = document.createElement("span");
        force.classList.add("force_team");
        force.innerHTML = `<i style="font-size:70%; position:relative; top:-1px;" class="fa-solid fa-shield-halved"></i>&nbsp;${parseFloat(asset.talentCard?.card_force || '50')}`;
        assetStart.appendChild(force);

        const asp = document.createElement("span");
        asp.classList.add("asset-top-middle");

        const averagecardvalue = t('avgCardValue');


        if (dispCurrency === "USD") {
          if (parseFloat(asset.talentCard?.USD_ratio) > 0) {
            asp.innerHTML = `<span class="asset-label"><span style="font-size:80%; opacity:75%; font-weight:200;">${averagecardvalue}</span><span><b>$${parseFloat(asset.talentCard?.USD_value).toFixed(2)}</b><span class="green-text">&nbsp;+${parseFloat(asset.talentCard?.USD_ratio).toFixed(2)}%</span></span></span>`;
          } else {
            asp.innerHTML = `<span class="asset-label"><span style="font-size:80%; opacity:75%; font-weight:200;">${averagecardvalue}</span><span><b>$${parseFloat(asset.talentCard?.USD_value).toFixed(2)}</b><span class="red-text">&nbsp;${parseFloat(asset.talentCard?.USD_ratio).toFixed(2)}%</span></span></span>`;
          }
        } else if (dispCurrency === "EUR") {
          if (parseFloat(asset.talentCard?.EUR_ratio) > 0) {
            asp.innerHTML = `<span class="asset-label"><span style="font-size:80%; opacity:75%; font-weight:200;">${averagecardvalue}</span><span><b>${parseFloat(asset.talentCard?.EUR_value).toFixed(2)}€</b><span class="green-text">&nbsp;+${parseFloat(asset.talentCard?.EUR_ratio).toFixed(2)}%</span></span></span>`;
          } else {
            asp.innerHTML = `<span class="asset-label"><span style="font-size:80%; opacity:75%; font-weight:200;">${averagecardvalue}</span><span><b>${parseFloat(asset.talentCard?.EUR_value).toFixed(2)}€</b><span class="red-text">&nbsp;${parseFloat(asset.talentCard?.EUR_ratio).toFixed(2)}%</span></span></span>`;
          }
        } else {
          asp.innerHTML = `${t('invalidCurrency')}`;
        }

        assetStart.appendChild(asp);

        const currentHostname = window.location.href;
    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const linkUrl = currentHostname.includes(`${stagingKey}`) 
      ? `https://${stagingKey}.peaxel.me` 
      : 'https://peaxel.me';

        if (!teamshortStatus){

        if (asset.talentCard?.available_cards == 0) { 
        const label1 = document.createElement("span");

        
       
       // label1.innerHTML = `<span class="call_action_member" style="opacity:0.5; font-weight:300; font-size:80%;"><i style="color:#bfbfbf;" class="fa-solid fa-id-badge"></i>&nbsp; {t('noCardAvailable')}</span>`;
      const noCardAvailableText = t('noCardAvailable', { count: asset.talentCard?.available_cards });
      label1.innerHTML = `<span class="call_action_member" style="opacity:0.5; font-weight:300; font-size:80%;"><i style="color:#bfbfbf;" class="fa-solid fa-id-badge"></i>&nbsp; ${noCardAvailableText}</span>`;
        
        label1.onclick = () => {
          window.open(`${linkUrl}/profile/?${asset.name}/`, '');
        };
        assetStart.appendChild(label1); }

        else {
          const label2 = document.createElement("span");
label2.className = "call_action_member";

// Create the icon element
const icon = document.createElement("i");
icon.style.color = "#bfbfbf";
icon.className = "fa-solid fa-id-badge";

// Create the text node for the translated message
const textNode = document.createTextNode(` ${t('cardsAvailable', { count: asset.talentCard?.available_cards })}`);

// Append the icon and text node to the main container
label2.appendChild(icon);
label2.appendChild(textNode);

// Add an onclick event to open the profile link in a new tab
label2.onclick = () => {
  window.open(`${linkUrl}/profile/?${asset.name}/`, '_blank');
};

// Append the label to the assetStart element
assetStart.appendChild(label2);
        }

    } else {

      
     // Extract the translation strings before constructing the HTML
const collectedCardsText = t('collectedCards', { count: asset.talentCard?.similar_cards_total }); // e.g., "Collected Cards" or "Collected Card"
const seeMyCardsText = asset.talentCard?.similar_cards_total > 1 
    ? t('seeMyCards')  // e.g., "See My Cards"
    : t('seeMyCard');  // e.g., "See My Card"

// Create the label2 element
const label2 = document.createElement("span");
label2.innerHTML = `
    <span class="call_action_member">
        <i style="color:#bfbfbf;" class="fa-solid fa-id-badge"></i>&nbsp;&nbsp;
        ${asset.talentCard?.similar_cards_total} ${collectedCardsText}
    </span>`;
label2.onclick = () => {
    window.open(`${linkUrl}/profile/?${asset.name}/`, '_blank');
};
assetStart.appendChild(label2);

// Create the sellButton element
const sellButton = document.createElement("span");
sellButton.innerHTML = seeMyCardsText;
sellButton.className = "button_market_asset_manage";
sellButton.onclick = () => {
    openSameCardsModal(asset.name, '');
};
assetStart.appendChild(sellButton);


    }

       
      }


        assetDiv.appendChild(assetStart);

        assetDiv.appendChild(assetDetails);

        let statusString = "";

        
      
      if (!talentVsCards || sameCardsStatus || buyoffersStatus || selloffersStatus) { 
        if (!teamshortStatus && asset.name !== 'emptyOne' ) { 
        const experience = document.createElement("span");
                if (!experience.hasChildNodes()) {
                  ReactDOM.render(
                    <Suspense fallback={<div>Loading Component A...</div>}>
                    <ExperienceDisplayLazy
              card={asset.talentCard}
              locationXP={locationPage}
            
      /></Suspense>,
                    experience
                  );
                }
                
        
         assetDetails.appendChild(experience);

         if (!locationPage.includes("dashboard")) { 
          if (todisplay !== 'lineup') { 

       // if (matchingOrder) {
          // Array to store promises
          

          let classNameX = "";

          if (modal === 'profile') {
            classNameX = "general_price_profile";
          } else {
            classNameX = "general_price";
          }

        
            
          if (asset.talentCard && parseInt(asset.talentCard?.offers_count) > 0) {
            
                const name = document.createElement("div");
                name.classList.add("asset-name");

                if (dispCurrency === "USD") {
                  if (parseInt(asset.talentCard?.offers_count) > 1) {
                    name.innerHTML = `<span class="${classNameX}"><span class="order-price">$${parseFloat(asset.talentCard?.dollar_max).toFixed(2)}</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.eth_max).toFixed(6)} ETH</span></span>`;
                  } else {
                    name.innerHTML = `<span class="${classNameX}"><span class="order-price">$${parseFloat(asset.talentCard?.dollar_max).toFixed(2)}</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.eth_max).toFixed(6)} ETH</span></span>`;
                  }
                } else if (dispCurrency === "EUR") {
                  if (parseInt(asset.talentCard?.offers_count) > 1) {
                    name.innerHTML = `<span class="${classNameX}"><span class="order-price">${parseFloat(asset.talentCard?.euro_max).toFixed(2)}€</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.eth_max).toFixed(6)} ETH</span></span>`;
                  } else {
                    name.innerHTML = `<span class="${classNameX}"><span class="order-price">${parseFloat(asset.talentCard?.euro_max).toFixed(2)}€</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.eth_max).toFixed(6)} ETH</span></span>`;
                  }
                } else {
                  name.innerHTML = `${t('invalidCurrency')}`;
                }

                name.onclick = () => {
                  if (parseInt(asset.talentCard?.current_order)>0) {
                    openOrderModal(asset.talentCard?.current_order, asset.talentCard, locationPage, state);
                  } else {
                    openAssetModal(asset, "", locationPage);
                  }
                };

                assetDetails.appendChild(name);
          }

      

          if (asset.talentCard && parseInt(asset.talentCard?.offers_count) === 0 && parseInt(asset.talentCard?.current_order) >10  ) {
                const name = document.createElement("div");
                name.classList.add("asset-name");

                if (dispCurrency === "USD") {
                  name.innerHTML = `<span class="${classNameX}"><span class="order-price">$${(
                    parseFloat(asset.talentCard?.desired_dollar) * 1.05).toFixed(2)}</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.desired_eth).toFixed(6)} ETH</span></span>`;
                } else if (dispCurrency === "EUR") {
                  name.innerHTML = `<span class="${classNameX}"><span class="order-price">${parseFloat(asset.talentCard?.desired_euro).toFixed(2)}€</span><span class="order-ETH">&nbsp;~${parseFloat(asset.talentCard?.desired_eth).toFixed(6)} ETH</span></span>`;
                } else {
                  name.innerHTML = `${t('invalidCurrency')}`;
                }

                name.onclick = () => {
                  if (parseInt(asset.talentCard?.current_order)>0) {
                    openOrderModal(asset.talentCard?.current_order, asset.talentCard, locationPage, state);
                  } else {
                    openAssetModal(asset, "", locationPage);
                  }
                };

                assetDetails.appendChild(name);
              }  

              if (asset.talentCard && parseInt(asset.talentCard?.offers_count) === 0 && parseInt(asset.talentCard?.current_order) <10  ) {
                const name = document.createElement("div");
                name.classList.add("asset-name");

                
                name.innerHTML = `<span>&nbsp;</span>`;
          

                assetDetails.appendChild(name);
              }  


        const info2 = document.createElement("span");
        info2.classList.add("asset-infoo");

        if (asset.talentCard && parseInt(asset.talentCard?.offers_count) > 0) {
        info2.innerHTML = `<span class="order-ETH">${parseInt(asset.talentCard?.offers_count)} ${parseInt(asset.talentCard?.offers_count) > 1 ? 'Offers' : 'Offer'}</span>`;
        } else {
        info2.innerHTML = `<span class="order-ETH">&nbsp;</span>`;
        }
        
        info2.onclick = () => {
          if (parseInt(asset.talentCard?.current_order)>0) {
            openOrderModal(asset.talentCard?.current_order, asset.talentCard, locationPage, state);
          } else {
            openAssetModal(asset, "", locationPage);
          }
        };

        assetDetails.appendChild(info2);

      
              if (asset.talentCard) {
              //  const { pending, inProgress, toTransfer } = orderInfo;

                if (parseInt(asset.talentCard?.toTransfer) > 0) {
                  statusString = `<span>${t('sold')}</span>`;
                } else {
                if (asset.talentCard?.owner === currentUser) {
                  if (parseInt(asset.talentCard?.current_order)>0) {
                  statusString = `<span>${t('manageListedCard')}</span>`;
                  } else 
                  {
                  if (asset.talentCard?.lineup == 0) {
                  statusString = `<span>${t('listForSale')}</span>`;
                  } else
                  { statusString = `<span>${t('lineup')}</span>`; }
                  }
                } else {
                  statusString = `<span>${t('makeAnOffer')}</span>`;
                }
                }
              }

              const sellButton = document.createElement("span");
              sellButton.innerHTML = statusString;
              if (asset.talentCard?.lineup == 0) { 
              sellButton.className = "button_market_asset_manage"; }
              else {
                sellButton.className = "button_market_asset_sell_blocked";
              }

              sellButton.onclick = () => {
                if (parseInt(asset.talentCard?.current_order)>0) {
                  openOrderModal(asset.talentCard?.current_order, asset.talentCard, locationPage, state);
                } else {
                  openAssetModal(asset, "", locationPage);
                }
              };

              assetDiv.appendChild(sellButton);
            } // end of NON lineup condition
            } // end of NON dashboard condition

      }} /// end of !talentVsCards
    

        assetsRowContainer.appendChild(assetDiv);
        tokenDiv.appendChild(assetsRowContainer);
      } else {
        console.error(`Div not found in the DOM`);
      }
    });
  }
}


const Footer = () => {

  const { t } = useTranslation();

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`) 
    ? `https://${stagingKey}.peaxel.me` 
    : 'https://peaxel.me';

  const [showStickyHeader, setShowStickyHeader] = useState(true);
  const [headerBackground, setHeaderBackground] = useState('#000000');



  return (
    
      <div className="footer-container">
        <div className="footer-full-row-top">
          <span className="socialPxl"> <a href="https://www.instagram.com/peaxel_game/"><i className="fa-brands fa-instagram"></i></a></span>
          <span className="socialPxl"> <a href="https://x.com/Peaxel8888"><i className="fa-brands fa-x-twitter"></i></a></span>
          <span className="socialPxl"> <a href="https://discord.com/invite/6tbvgYgJ"><i className="fa-brands fa-discord"></i></a></span>
          <span className="socialPxl"> <a href="https://www.youtube.com/@Peaxel.8888"><i className="fa-brands fa-youtube"></i></a></span>
          <span className="socialPxl"> <a href="https://www.linkedin.com/company/peaxel/"><i className="fa-brands fa-linkedin-in"></i></a></span>
          <span className="socialPxl"> <a href="https://telegram.me/peaxel_game"><i className="fa-brands fa-telegram"></i></a></span>
          <span className="socialPxl"> <a href="https://medium.com/@peaxel"><i className="fa-brands fa-medium"></i></a></span>
        </div>
        <div className="footer-columns">
          <div className="footer-column">
            <ul>
              <li><a href={`${linkUrl}/peaxel-terms-and-conditions/`}>{t('termsAndConditions')}</a></li>
              <li><a href={`${linkUrl}/peaxel-privacy-policy/`}>{t('privacyPolicy')}</a></li>
              <li><a href={`${linkUrl}/peaxel-privacy-policy/`}>{t('cookiePolicy')}</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <ul>
              <li><a href={`${linkUrl}`}>{t('home')}</a></li>
              <li><a href={`${linkUrl}/game/`}>{t('enterTheGame')}</a></li>
              <li><a href={`${linkUrl}/blog-slow-down/`}>{t('blog')}</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <ul>
              <li><a href={`${linkUrl}/advantages-for-talents-on-peaxel/`}>{t('talentAdvantages')}</a></li>
            </ul>
          </div>
          <div className="footer-column">
          </div>
        </div>
        <div className="footer-full-row">
          <span>
            <Suspense fallback={<div>{t('loading')}</div>}>
              <CurrencyToggleLazy />
            </Suspense>
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            <Suspense fallback={<div>{t('loading')}</div>}>
              <LanguageSelectorLazy />
            </Suspense>
          </span>
        </div>
      </div>
  
  );
};


const App = () => {

const { t } = useTranslation();
// initialise Immutable X Link SDK
//const linkIframeOptions: ConfigurableIframeOptions = {
 // className: 'my-link-iframe',
//};

const linkIframeOptions: ConfigurableIframeOptions = useMemo(() => ({
  className: 'my-link-iframe',
}), []);


//const link = new Link(process.env.REACT_APP_SANDBOX_LINK_URL, linkIframeOptions);
const link = useMemo(() => new Link(process.env.REACT_APP_SANDBOX_LINK_URL, linkIframeOptions), [linkIframeOptions]);

const publicApiUrl = useMemo(() => process.env.REACT_APP_SANDBOX_ENV_URL ?? '', []);

const [wallet, setWallet] = useState<string | undefined>(undefined);
const [balance, setBalance] = useState<ImmutableMethodResults.ImmutableGetBalanceResult | null>(null);
const [client, setClient] = useState<any>(null); // Initialize your client here
const [ethBalance, setEthBalance] = useState<number | null>(null);
const [assets, setAssets] = useState<Asset[]>([]);
const [walletAddress, setWalletAddress] = useState<string | null>(null); // Initialize wallet address here
const [isWalletMenuOpen, setIsWalletMenuOpen] = useState(false);
const [isDashboardPage, setIsDashboardPage] = useState(true);
const [hasWallet, setHasWallet] = useState(false);
const [shouldRenderHeader, setShouldRenderHeader] = useState(false);
const [shouldInitializeWallet, setShouldInitializeWallet] = useState(false);
const [refresh, setRefresh] = useState(0);
const [selectedAsset, setSelectedAsset] = useState<SelectedAsset | null>(null);
const [selectedTalent, setSelectedTalent] = useState<{ assetName: string, toDisplay: string } | null>(null);
const [exchangeRates, setExchangeRates] = useState<{ USD: number; EUR: number } | null>(null);
const [selectedOrder, setSelectedOrder] = useState<{
  order: Order | null;
  exchangeRates: { USD: number; EUR: number } | null;
  currency: 'EUR' | 'USD';
}>({
  order: null,
  exchangeRates: null,
  currency: 'USD', // Set the default currency as needed
});
const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
const [isSameCardsModalOpen, setIsSameCardsModalOpen] = useState(false);
const [ethBalanceInDollars, setEthBalanceInDollars] = useState<number | null>(null);
const selectedCurrency = localStorage.getItem('pxlCurrency');
const [isPendingOffers, setIsPendingOffers] = useState(0);

const isLoginPage = window.location.pathname.includes('/login'); // Add this line

const [isStaging, setIsStaging] = useState<boolean>(false);

const [isPageViewModalVisible, setIsPageViewModalVisible] = useState(false);
const [assetProfileView, setAssetProfileView] = useState<Asset | null>(null);
const [whatPage, setWhatPage] = useState('dashboard');
const [activeSection, setActiveSection] = useState('');
const [whatModal, setWhatModal] = useState('');
const [talentProfile, setTalentProfile] = useState<{ assetTalent: string, toDisplayTalent: string } | null>(null);

const [currentURLState, setCurrentURLState] = useState(0);
const [indexLanguage, setIndexLanguage] = useState('en');

const [onGame, setOnGame] = useState(false);
const [onTalentAdv, setOnTalentAdv] = useState(false);

const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false); // State to manage the modal

const [isTalentSignupModalOpen, setIsTalentSignupModalOpen] = useState(false);

const [isScrolled, setIsScrolled] = useState(false);

const currentHostname = window.location.href;
const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
const linkUrl = currentHostname.includes(`${stagingKey}`) 
  ? `https://${stagingKey}.peaxel.me` 
  : 'https://peaxel.me';

const handleTalentSignupOpen = () => {
  setIsTalentSignupModalOpen(true);
};

const handleTalentSignupClose = () => {
  setIsTalentSignupModalOpen(false);
};



  useLayoutEffect(() => {
 

    const gameIn = window.location.pathname.includes('/game');
    setOnGame(gameIn);

    const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const isStagingPath = window.location.pathname.includes(`${stagingKey}`);
    setIsStaging(isStagingPath);

    console.log('Initial onGame:', gameIn);
    console.log('Initial isStaging:', isStagingPath);

    const talentAdvIn = window.location.pathname.includes('/advantages-for-talents-on-peaxel');
    setOnTalentAdv(talentAdvIn);

    
  }, []);

  useEffect(() => {

    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; 
    setIndexLanguage(storedLanguage);

    const storedCurrency = localStorage.getItem('pxlCurrency') || 'USD'; 
    localStorage.setItem('pxlCurrency',storedCurrency);


    if (onGame) {
      const savedWalletAddress = localStorage.getItem('walletAddress');
      handleLinkClick('dashboard', '');

      if (savedWalletAddress === null || savedWalletAddress.length <= 8) {
        setWallet('0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a');
        localStorage.setItem('walletAddress', '0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a');
        
        setIsWelcomeModalOpen(true); 
      }

      const pageIni = localStorage.getItem('pxlPage');
      const sectionIni = localStorage.getItem('pxlSection');
      if (pageIni !== '') {
        if (sectionIni !== '') {
          handleLinkClick(pageIni, sectionIni);
        } else {
          handleLinkClick('dashboard', '');
        }
      }
    } else {
      localStorage.setItem('pxlPage', '');
      localStorage.setItem('pxlSection', '');
    }

    if (onGame===true) {
      const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
    const isStagingPath = window.location.pathname.includes(`${stagingKey}`);

      const savedWalletAddress = localStorage.getItem('walletAddress');
      handleLinkClick('dashboard', '');

      if ((savedWalletAddress !== null && savedWalletAddress.length > 8) || isStagingPath) {
        setIsWelcomeModalOpen(false); 
      } else {
        setIsWelcomeModalOpen(true); 
      }
    }
  }, [onGame]);




  const handleCloseWelcomeModal = () => {
    setIsWelcomeModalOpen(false);
  };


const handleLinkClick = (page:any, section:any) => {
  const newUrl = `${linkUrl}/game/?page=${encodeURIComponent(page)}&section=${encodeURIComponent(section)}`;
  window.history.pushState({}, '', newUrl); // Update the URL
  localStorage.setItem('pxlPage',page);
  localStorage.setItem('pxlSection',section);
  setCurrentURLState(currentURLState +1);
};

useEffect(() => {
  const handlePopState = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page = urlParams.get('page');
    const section = urlParams.get('section');

    if (page !== null) {
      setWhatPage(decodeURIComponent(page));
      setActiveSection(section ? decodeURIComponent(section) : '');
    } else {
      setWhatPage('dashboard');
      setActiveSection('');
    }
  };

  handleRefresh();

  window.addEventListener('popstate', handlePopState);
  // Call handlePopState to set the initial state based on the URL
  handlePopState();

  

  return () => {
    window.removeEventListener('popstate', handlePopState);
  };

}, [currentURLState]);






useEffect(() => {
  const handlePopState = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page = urlParams.get('page');
    const section = urlParams.get('section');

    if (page !== null) {
      setWhatPage(decodeURIComponent(page));
      setActiveSection(section ? decodeURIComponent(section) : '');
    } else {
      setWhatPage('dashboard');
      setActiveSection('');
    }
  };

  handleRefresh();

  window.addEventListener('popstate', handlePopState);
  // Call handlePopState to set the initial state based on the URL
  handlePopState();

  return () => {
    window.removeEventListener('popstate', handlePopState);
  };

}, [currentURLState]);


useEffect(() => {
  const handleURLChange = () => {
    setCurrentURLState((prevState) => prevState + 1);
  };

  window.addEventListener('popstate', handleURLChange);

  return () => {
    window.removeEventListener('popstate', handleURLChange);
  };
}, []);





///// DETERMINE IF THERE ARE PENDING OFFERS AND DISPLAY THEM IN THE HEADER MENU


useEffect(() => {
  const initializePendingOffers = () => {
    const pendingOffers = parseInt(localStorage.getItem('pendingOffers') ?? '0', 10);
    setIsPendingOffers(pendingOffers);
  };

  const updatePendingOffers = () => {
    const pendingOffers = parseInt(localStorage.getItem('pendingOffers') ?? '0', 10);

    if (pendingOffers !== isPendingOffers) {
      setIsPendingOffers(pendingOffers);
    }

    if (pendingOffers > 0) {
      const tableHtml = `
        <span class="button-search"><i class="fa-solid fa-money-check-dollar"></i></span>
        <span class="numberPendingOffers">${pendingOffers}</span>
      `;
      const targetDiv = document.getElementById('displayPendingOffers');
      if (targetDiv) {
        requestAnimationFrame(() => {
          
          targetDiv.className = ''; // Clear className if needed
          targetDiv.innerHTML = tableHtml; // Set the HTML string
        });
      }
    } else {
      const targetDiv = document.getElementById('displayPendingOffers');
      if (targetDiv) {
        requestAnimationFrame(() => {
          targetDiv.innerHTML = ''; // Clear the inner HTML
        });
      }
    }
  };

  initializePendingOffers(); // Initialize on first load
  updatePendingOffers(); // Update on dependency changes

}, [activeSection, isPendingOffers, whatPage]); // Dependencies

////////////////////////

const closePageViewModal = useCallback(() => {
 // setRefresh(prev => prev + 1);
  setWhatModal('');
  setAssetProfileView(null);
  setTalentProfile(null)
  setIsPageViewModalVisible(false);
  
  

}, []);


 const closeAssetModal = useCallback(() => {
  //setRefresh(prev => prev + 1);
  setSelectedAsset(null);
  setIsAssetModalOpen(false);
  
}, []);

const closeSameCardsModal = useCallback(() => {
  //setRefresh(prev => prev + 1);
  setSelectedTalent(null);
  setIsSameCardsModalOpen(false);
  
}, []);

 const closeOrderModal = useCallback(() => {
  setSelectedOrder({
    order: null,
    exchangeRates: null,
    currency: 'USD',
  });
 // setRefresh(prev => prev + 1);
  setIsOrderModalOpen(false);
  
  
}, []);


const handleToggleWalletMenu = useCallback(() => {
  setIsWalletMenuOpen(prev => !prev);
}, []);

const handleProfileButtonClick = useCallback((talentName: string, toDisplay: string) => {
openSameCardsModal(talentName,toDisplay);
}, [client]);




const buildIMX = useCallback(async () => {
  const savedWalletAddress = localStorage.getItem('walletAddress');
  const clientInstance = await ImmutableXClient.build({ publicApiUrl });
  setClient((prevClient:any) => (prevClient !== clientInstance ? clientInstance : prevClient));
  if (savedWalletAddress) {
    setWallet((prevWallet) => (prevWallet !== savedWalletAddress ? savedWalletAddress : prevWallet));
    await fetchAssets(clientInstance, savedWalletAddress, setAssets, refresh, '', activeSection,'', whatPage, whatModal);
    const balance = await clientInstance.getBalance({ user: savedWalletAddress, tokenAddress: 'eth' });
    setBalance((prevBalance) => (prevBalance !== balance ? balance : prevBalance));
     }
}, [publicApiUrl]);


useEffect(() => {
  async function fetchExchangeRates() {
    try {
      // Fetch USD rate
      const responseUsd = await axios.get(
        `${linkUrl}/wp-json/custom/v1/get_metadata_by_uppername?uppername=HELLO@PEAXEL.ME&metadata_label=rate_usd`
      );
      const usdRate = responseUsd.data/100000000000;
      localStorage.setItem('usdRate', usdRate.toString());

      // Fetch EUR rate
      const responseEur = await axios.get(
        `${linkUrl}/wp-json/custom/v1/get_metadata_by_uppername?uppername=HELLO@PEAXEL.ME&metadata_label=rate_eur`
      );
      const eurRate = responseEur.data/100000000000;
      localStorage.setItem('eurRate', eurRate.toString());

      // Update the state with both rates
      setExchangeRates({ USD: usdRate, EUR: eurRate });
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
    }
  }

  fetchExchangeRates();
}, []);

useEffect(() => {
  const openAssetModal = (asset: Asset, order_id: string, locationPath: string) => {
    // Close the order modal if it's open
    setIsOrderModalOpen(false);

    setSelectedAsset({ asset, order_id, locationPath });
    if (whatPage.includes('team') || whatPage.includes('dashboard') || whatModal === 'profile' || whatPage.includes('market')) {
      setIsAssetModalOpen(true); // Open the modal
    }
  };

  setOpenAssetModalFunc(openAssetModal);

}, [exchangeRates]);

useEffect(() => {
  const openSameCardsModal = (assetName: string, toDisplay: string) => {
    // Close the order modal if it's open
   setSelectedTalent({ assetName, toDisplay });
   console.log('SelectedTalent',selectedTalent);
  setIsSameCardsModalOpen(false);
 
   
   if (whatPage.includes('team') || whatModal === 'profile' || whatPage.includes('market') || whatPage.includes('dashboard')) {
    setIsSameCardsModalOpen(true); // Open the modal
  }
   
  };

  setOpenSameCardsModalFunc(openSameCardsModal);

}, [exchangeRates]);



useEffect(() => {
  const openPageViewModal = async (asset: Asset, toDisplayTalent: string) => {
    // Close the order modal if it's open
    setTalentProfile({assetTalent:asset.name, toDisplayTalent:toDisplayTalent});
    setWhatModal('profile');
    setAssetProfileView(asset);
    console.log('setPageViewModalUr',assetProfileView);
    setIsPageViewModalVisible(false);
    if (whatPage.includes('team') || whatPage.includes('market') || whatPage.includes('dashboard')) {
    setIsPageViewModalVisible(true);
  }
   
  };

  setPageViewModalFunc(openPageViewModal);

}, [exchangeRates]);



useEffect(() => {
  const openOrderModal = async (order_id: string, cardProp: TalentCard, locationPath: string, activeSection: string) => {
    // Close the asset modal if it's open
    setIsAssetModalOpen(false);
  
    let orderManage: Order | null = null;
  
    try {
      const orderEndpoint = `https://api.sandbox.x.immutable.com/v3/orders/${order_id}`;
  
      // Make the API call to get order details
      const orderResponse = await axios.get(orderEndpoint);
  
      orderManage = orderResponse.data;

      if (orderManage) {
        // Find the corresponding asset for the order and enrich the order with asset.talentCard
        const matchingAsset = assets.find(asset => 
        asset.token_address === orderManage!.sell.data.token_address && 
        asset.token_id === orderManage!.sell.data.token_id
        );
  
        if (matchingAsset) {
          orderManage.talentCard = cardProp;
        }
      }
  
    } catch (error) {
      console.error('Error fetching currency conversion:', error);
    }
  
    const oCurrency = localStorage.getItem('pxlCurrency') || 'USD';
  
    setSelectedOrder({
      order: orderManage,
      exchangeRates: exchangeRates,
      currency: oCurrency as 'EUR' | 'USD',
    });
  
    if (whatPage.includes('team') || whatPage.includes('dashboard') || whatModal === 'profile' || whatPage.includes('market')) {
      setIsOrderModalOpen(true); // Open the modal
    }
  };
  
  setOpenOrderModalFunc(openOrderModal);
  

}, [exchangeRates]);


const fetchAssets = useCallback( 
  async (
  client: ImmutableXClient | null, // The ImmutableX client instance or null (provided by your application)
  savedWalletAddress: string | null, // The wallet address as a string or null (retrieved from local storage)
  setAssets: React.Dispatch<React.SetStateAction<Asset[]>>, // A state updater function for assets
  refresh: number, // A callback to display assets on the page
  talentName: string, 
  state: string,
  toDisplay: string,
  locationPage: string,
  modal: string,
) => {

  // case of visitors

  if ( (savedWalletAddress && savedWalletAddress.length < 6)  || savedWalletAddress === null) {
    savedWalletAddress = '0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a';
  }

  try {

    let assetCursor;
    let fetchedAssets: Asset[] = [];
    talentName = talentName.toUpperCase();

      do {

       
        // Continue fetching assets using pagination (cursor)
        //if (savedWalletAddress ) {
        const assetRequest: any = await client?.getAssets({
          cursor: assetCursor,
          collection: '0x14464556d7979ab59426158f7f9181c8359c7bfc',
        });
        // Concatenate the newly fetched assets to the existing list
        fetchedAssets = fetchedAssets.concat(assetRequest.result);
        assetCursor = assetRequest.cursor;
     // }

      } while (assetCursor);

      // Update the assets state with the fetched assets
      setAssets(fetchedAssets);

      const promises = [];

      
    if (modal === 'profile') {

    if (toDisplay === '') {
        promises.push(displayAllAuctions(fetchedAssets, client, refresh, talentName, state, '', locationPage, modal,t as unknown as (key: string, options?: any) => string)); 
      }
      
    else if (toDisplay === 'buyoffers') {
        promises.push(displayAllAuctions(fetchedAssets, client, refresh, talentName, state, 'buyoffers', locationPage, modal,t as unknown as (key: string, options?: any) => string));
    }
    else if (toDisplay === 'selloffers') {
      promises.push(displayAllAuctions(fetchedAssets, client, refresh, talentName, state, 'selloffers', locationPage, modal,t as unknown as (key: string, options?: any) => string)); 
    }
    
  } else {

      if ((locationPage.includes('team'))) {

      if (talentName.length > 5) {
  
      if ((state === 'allMyTalents' || state === 'overView')) {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, talentName, state, 'samecards', locationPage, '',t as unknown as (key: string, options?: any) => string));
      }}
      
      else {
        
        if (state === 'overView' && toDisplay !== 'addlineup') {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'lineup', locationPage, modal,t as unknown as (key: string, options?: any) => string));
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'teamshort', locationPage, modal,t as unknown as (key: string, options?: any) => string));
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'cardsportfolio', locationPage, modal,t as unknown as (key: string, options?: any) => string));
        } 
        else if ((state === 'myLineup' || state === 'overView' || locationPage.includes('dashboard')) && toDisplay === 'addlineup') {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'addlineup', locationPage, modal,t as unknown as (key: string, options?: any) => string));
        }
        else if (state === 'myLineup' && toDisplay !== 'addlineup') {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'lineup', locationPage, modal,t as unknown as (key: string, options?: any) => string));
        }
        else if (state === 'allMyTalents') {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'teamshort', locationPage, modal,t as unknown as (key: string, options?: any) => string));

        }
        else if (state === 'allMyCards') {
          promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'cardsportfolio', locationPage, modal,t as unknown as (key: string, options?: any) => string));
        }
    } 

      }
    
     else if (locationPage.includes('dashboard') ) {
      
      if(toDisplay !== 'addlineup') {
        promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'lineup', locationPage, '',t as unknown as (key: string, options?: any) => string));
      } 
      else {
        promises.push(displayAllAuctions(fetchedAssets, client, refresh, '', state, 'addlineup', locationPage, '',t as unknown as (key: string, options?: any) => string));
      }

    }
    
     else if (locationPage.includes('market')) {
        promises.push(displayAllAuctions(fetchedAssets, client, refresh,'', state, '', locationPage, '',t as unknown as (key: string, options?: any) => string));
      }

    }

      await Promise.all(promises);
    //}
  } catch (error) {
    // Handle any errors that occur during asset fetching
    console.error('Error fetching assets:', error);
  }
},
[client, setAssets, refresh]
);


useEffect(() => {

  buildIMX();
  setShouldRenderHeader(true);
  setShouldInitializeWallet(true);
  /*balanceChart();*/
  /*transaction_action();*/

}, [buildIMX]);

useEffect(() => {
  if (shouldInitializeWallet) {
    const savedWalletAddress = localStorage.getItem('walletAddress');
    if (savedWalletAddress) {
      setWallet(savedWalletAddress);
      setHasWallet(savedWalletAddress.length > 8);
    }
  }
}, [shouldInitializeWallet]);

///2
useEffect(() => {
  const savedWalletAddress = localStorage.getItem('walletAddress');

if (whatModal === 'profile' && talentProfile !== null){
  if (client && savedWalletAddress && isSameCardsModalOpen && selectedTalent !==null) {   
  fetchAssets(client, savedWalletAddress, setAssets, refresh, selectedTalent.assetName, '',selectedTalent.toDisplay, '', whatModal);  
}

if (client && savedWalletAddress && !isSameCardsModalOpen ) {   
  fetchAssets(client, savedWalletAddress, setAssets, refresh, talentProfile.assetTalent, '','', '', whatModal);  

}

} else {
  if (client && savedWalletAddress && !isSameCardsModalOpen && selectedTalent ===null) {   
  fetchAssets(client, savedWalletAddress, setAssets, refresh,'', activeSection,'', whatPage, '');  
  }
  if (client && savedWalletAddress && isSameCardsModalOpen && selectedTalent !==null) {   
  fetchAssets(client, savedWalletAddress, setAssets, refresh, selectedTalent.assetName, activeSection,selectedTalent.toDisplay, whatPage, '');
  }
}
  
}, [client, refresh, isSameCardsModalOpen, talentProfile, whatModal]);





useEffect(() => {
  const fetchAssets = async () => {
    try {
      if (client && walletAddress) {
        let assetCursor;
        let fetchedAssets: Asset[] = [];

        do {
          const assetRequest: AssetResponse = await client.getAssets({
            user: walletAddress,
            cursor: assetCursor,
          });

          fetchedAssets = fetchedAssets.concat(assetRequest.result);
          assetCursor = assetRequest.cursor;
        } while (assetCursor);

        setAssets(fetchedAssets);
      }
    } catch (error) {
      console.error('Error fetching assets:', error);
    }
  };
  if (client && walletAddress) {
    fetchAssets();
  }
}, [client, walletAddress]);


useEffect(() => {
  // Define an async function to fetch the ETH balance
  async function fetchEthBalance(walletAddress: any) {
    try {
      const response = await axios.get(`https://api.sandbox.x.immutable.com/v2/balances/${walletAddress}`);
      
      if (response.data && response.data.result && response.data.result.length > 0) {
        const ethBalanceHex = response.data.result[0].balance;
        const ethBalanceDecimal = hexToDecimal(ethBalanceHex, 18); // Assuming 18 decimals for ETH
        localStorage.setItem('pxlBalance',ethBalanceDecimal.toString());
        
        return ethBalanceDecimal;
      }
      
      return null; // Return null if the response does not contain the balance
    } catch (error) {
      console.error('Error fetching ethBalance:', error);
      return null; // Return null in case of an error
    }
  }

  function hexToDecimal(hex: string, decimals: number): number {
    const hexValue = BigInt(hex);
    const divider = BigInt(10 ** decimals);
    return Number(hexValue) / Number(divider);
  }

  // Fetch ETH to USD and ETH to EUR conversion rates when selectedCurrency changes
  async function fetchEthToCurrencyConversion() {
      try {
       
        const usdRateString = localStorage.getItem('usdRate');
        const eurRateString = localStorage.getItem('eurRate');

        // Convert string to number using parseFloat. Use 0 or any default value as fallback if null
        const ethToUsdRate = usdRateString ? parseFloat(usdRateString) : 1;
        const ethToEurRate = eurRateString ? parseFloat(eurRateString) : 1;

      const WalletBalance = localStorage.getItem('walletAddress');

        if (selectedCurrency === 'USD' && ethToUsdRate !== undefined) {
        // Fetch the ETH balance using the wallet address
        const ethBalance: number = WalletBalance ? await fetchEthBalance(WalletBalance) ?? 0 : 0;

        localStorage.setItem('pxlBalance',ethBalance.toString());

       
        balancePush(ethBalance);
        

        if (ethBalance !== null) {
          const amountInDollars = ethBalance * ethToUsdRate;
          
          setEthBalanceInDollars(amountInDollars);
          setEthBalance(ethBalance);
          localStorage.setItem('pxlBalance',ethBalance.toString());
      
          balancePush(ethBalance);
        }
      } else if (selectedCurrency === 'EUR' && ethToEurRate !== undefined) {
        // Fetch the ETH balance using the wallet address
        const ethBalance: number = WalletBalance ? await fetchEthBalance(WalletBalance) ?? 0 : 0;
        localStorage.setItem('pxlBalance',ethBalance.toString());
     
        balancePush(ethBalance);

        if (ethBalance !== null) {
          const amountInEur = ethBalance * ethToEurRate;
          setEthBalanceInDollars(amountInEur);
          setEthBalance(ethBalance);
          localStorage.setItem('pxlBalance',ethBalance.toString());
     
          balancePush(ethBalance);
        }
      }
    } catch (error) {
      console.error('Error fetching ETH conversion rate:', error);
    }
  }

  // Call the fetchEthToCurrencyConversion function
  fetchEthToCurrencyConversion();
  setIsWalletMenuOpen(false);
}, [selectedCurrency]); 


const handleRefresh = useCallback(() => setRefresh((prev) => prev + 1), []);

useEffect(() => {
  setHandleRefreshFunc(handleRefresh); // Set the handleRefresh function
}, []);


useEffect(() => {
  const footerPxlDiv = document.getElementById('footerPxl');
  if (footerPxlDiv) {
    const root = createRoot(footerPxlDiv);
    root.render(<Footer />);
  }
}, [onGame]);


useEffect(() => {
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


return (

<div className="App" key={refresh}>

{(onGame === false && onTalentAdv === false) && (
         <div className={`sticky-header-onboard ${isScrolled ? 'scrolled' : 'initial'}`}>
         <a href={`${linkUrl}`}><img className="sticky-logo-onboard" src={`${linkUrl}/wp-content/uploads/2024/06/logo_peaxel_1000x200_transparent-3.png`} alt="Peaxel Logo" /></a>
         <div id="TalentCall">
         <span className="talentSignUp" style={{ cursor: 'pointer' }} onClick={() => { handleTalentSignupOpen(); }}>
           {t ('iAmATalent')}
            </span>
          </div>
        </div>
      )}

{(onGame === false && onTalentAdv === true) && (
         <div className={`sticky-header-onboard ${isScrolled ? 'scrolled' : 'initial'}`}>
         <a href={`${linkUrl}`}><img className="sticky-logo-onboard" src={`${linkUrl}/wp-content/uploads/2024/06/logo_peaxel_1000x200_transparent-3.png`} alt="Peaxel Logo" /></a>
         <div id="TalentCall">
         <span className="talentSignUp" style={{ cursor: 'pointer' }} onClick={() => { handleTalentSignupOpen(); }}>        
          APPLY NOW
            </span>
          </div>
        </div>
      )}



{isWelcomeModalOpen && <WelcomeModal onClose={handleCloseWelcomeModal} />} {/* Render the modal if needed */}

{isTalentSignupModalOpen && <TalentSignupModal onClose={handleTalentSignupClose} />}

<Suspense fallback={<div>{t('loading')}</div>}>
<PageViewModalLazy 
        isVisible={isPageViewModalVisible} 
        onClose={() => closePageViewModal()} 
        asset={assetProfileView}
        dispCurrency={selectedCurrency}
        handleProfileButtonClick={handleProfileButtonClick}
      />
</Suspense>

  <Suspense fallback={<div>{t('loading')}</div>}>
  {selectedTalent && (
    <ModalSameCardsLazy 
      assetName={selectedTalent.assetName} 
      onClose={closeSameCardsModal} 
      refresh={refresh}
      activeSection={activeSection}
    />
    )}
    </Suspense>


<Suspense fallback={<div>{t('loading')}</div>}>
  {selectedAsset && (
    <AssetModalLazy asset={selectedAsset.asset} saleID={selectedAsset.order_id} onClose={closeAssetModal} locationPath={whatPage} />
  )}
 
  {selectedOrder && selectedOrder.order && (
    <OrderModalLazy 
      order={selectedOrder.order} 
      onClose={closeOrderModal}
      exchangeRates={selectedOrder.exchangeRates}
      currency={selectedOrder.currency as "USD" | "EUR"}
      locationPath={whatPage}
      activeSection={activeSection}
    />
  )}
</Suspense>

{(onGame === true && (whatPage === 'dashboard' || whatPage === 'team' || whatPage === 'market')) && (
<div id="GlobalPeaxel">
<div id="tokenContainerAllAuctions">

  { (whatPage === 'dashboard') && (
    <div className="image-container-dashboard">
   
    <img onClick={() => handleLinkClick('market','allTalents')}
    src={`${linkUrl}/wp-content/uploads/2024/05/1-4.png`}  alt="Collect Talent Cards" />
    
    <img onClick={() => handleLinkClick('team','allMyCards')}
    src={`${linkUrl}/wp-content/uploads/2024/05/2-5.png`}  alt="Buy & Sell your Cards" />
   
    <img onClick={() => handleLinkClick('team','myLineup')}
    src={`${linkUrl}/wp-content/uploads/2024/05/3-4.png`}  alt="Lineup and win" />
   
</div>
  )}


{(onGame === true && (whatPage === "dashboard")) && (
  <div id="titleStats">
    <div style={{ color: '#FAFAFA', width: '100% !important', fontWeight: '400', fontSize: '120%', paddingTop: '20px', paddingBottom: '20px', borderTop: '1px solid #161616' }}>
        <i style={{ opacity: '0.5', fontSize: '75%' }} className="fa-solid fa-users-rectangle"></i> {t('myTeam')}
      </div>
    <div className="teamStatistics">
      <div id="teamStats1" onClick={() => handleLinkClick('team', 'myLineup')}></div>
      <div id="teamStats2" onClick={() => handleLinkClick('team', 'allMyTalents')}></div>
      <div id="teamStats3" onClick={() => handleLinkClick('team', 'allMyCards')}></div>
      <div id="teamStats4" onClick={() => handleLinkClick('market', 'listedTalents')}></div>
    </div>
  </div>
)}

{ (onGame === true && (activeSection ==="overView")) && (
  <div id="titleStats">
  <div className="teamStatistics">
  <div id="teamStats1" onClick={() => handleLinkClick('team','myLineup')}></div>
  <div id="teamStats2" onClick={() => handleLinkClick('team','allMyTalents')}></div>
  <div id="teamStats3" onClick={() => handleLinkClick('team','allMyCards')}></div>
  <div id="teamStats4" onClick={() => handleLinkClick('market','listedTalents')}></div>
  </div>
  </div>


)}

{ (onGame === true && (activeSection ==="overView")) && (
  <div id="titleLineup"></div>
  )}
  { (onGame === true && (activeSection ==="overView" || activeSection ==="myLineup")) && (
  <div id="tokensLineup"></div>
  )}
  
  { (onGame === true && (whatPage ==="dashboard")) && (
  <div id="titleMarket"></div>
  )}
  { (onGame === true && (whatPage ==="dashboard")) && (
  <div id="marketStats"></div>
  )}

  { (onGame === true && (activeSection ==="overView")) && (
  <div id="titleTeamShort"></div>
  )}

  { (onGame === true && (activeSection ==="overView" || activeSection ==="allMyTalents"  )) && (
  <div id="tokensteamshort" ></div>
  )}

{ (onGame === true && (activeSection ==="overView" )) && (
  <div id="titleCardsPortfolio"></div>
  )}


  { (onGame === true && (activeSection ==="overView" || activeSection ==="allMyCards"  )) && (
  <div id="tokenscardsportfolio" ></div>
  )}
  
  <div id="tokensAllAuctions"></div>

  {(onGame === true) && (
  <div><Suspense fallback={<div>Loading...</div>}><Footer /></Suspense></div>
  )}

</div>
</div>
)}

<Suspense fallback={<div>Loading...</div>}>
    {(shouldRenderHeader && onGame === true )&& (
<OptimizedHeaderLazy
  hasWallet={hasWallet}
  wallet={wallet || ''} // Provide a default empty string if wallet is null or undefined
  ethBalanceInDollars={ethBalanceInDollars}
  selectedCurrency={selectedCurrency || 'USD'} // Provide a default value if selectedCurrency is null
  isWalletMenuOpen={isWalletMenuOpen}
  handleToggleWalletMenu={handleToggleWalletMenu}
  activeSection={activeSection} 
  setActiveSection={setActiveSection}
  whatPage={whatPage}
  setWhatPage={setWhatPage}
  currentURLState={currentURLState}
  setCurrentURLState={setCurrentURLState}
  onGame={onGame}
/>
 
)}
</Suspense>



</div>
 
);
};

export default App;